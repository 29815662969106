/// <reference path='./main.d.ts'/>

/**
 * Greatly 'inspired' by RealWorld: https://github.com/adamhaile/surplus-realworld
 */

import S from "s-js";
import * as Surplus from "surplus";

import { App } from "./app/main/app";
import { Router } from "./app/router";
import { Login } from './components/Login';
import { HomeRoute } from "./components/main/Home";
import { StoreRoute } from "./components/main/Store";
import { ProductRoute } from "./components/main/Product";
import { CartRoute } from "./components/main/Cart";
import { EventsRoute } from "./components/main/Events";
import { BrickAndMortarRoute } from "./components/main/BrickAndMortar";
import { CheckoutRoute } from "./components/main/CheckOut";
import { LegalRoute } from "./components/Legal";



S.root(() => {
	const
		app = App(),
		page = Router(app.location.change, () => null, [
			[/^#?\/?$/,
					()					=> HomeRoute(app) ],
			[/^#\/store\/?$/,
					()					=> StoreRoute(app) ],
			// /store/category/:slug
			[/^#\/store\/category\/([^\/]*)\/?$/,
					([, slug])			=> StoreRoute(app, {category: slug}) ],
			// /store/tags/:0/:1/:2/:3
			[/^#\/store\/tags\/([^\+]+)\+?([^\+]+)?\+?([^\+]+)?\+?([^\+]+)?/,
					([, ...tags])		=> StoreRoute(app, {tags: tags}) ],
			// /store/:slug
			[/^#\/store\/([^\/]+)(\/.*)?$/,
					([, id])			=> ProductRoute(app, {id}) ],
			
			[/^#\/cart\/?$/,
					()					=> CartRoute(app) ],
					
			[/^#\/events\/?$/,
					()					=> EventsRoute(app) ],
					
			[/^#\/brick-and-mortar\/?$/,
					()					=> BrickAndMortarRoute(app) ],

			[/^#\/log-in\/?$/,
					()					=> Promise.resolve(() => <Login fire={app.fire} />) ],

			[/^#\/check-out\/?$/,
					()					=> CheckoutRoute(app) ],

			// /legal/tos or /legal/privacy
			[/^#\/legal\/tos\/?$/,
					()					=> LegalRoute('tos') ],
			[/^#\/legal\/privacy\/?$/,
					()					=> LegalRoute('privacy') ],
					
			[/^#\/(.*)$/,
					()					=> HomeRoute(app) ],
		]);
	addEventListener('load', ()=>{document.body.appendChild(<div id='m'>{page}</div>);});
});
